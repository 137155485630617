<template>
  <span
    ref="editable"
    contenteditable
    v-on="listeners"
    :class="{
      'text-success': +value >= 0 && color,
      'text-danger': +value < 0 && color,
      'text-strong': bold,
    }"
    onclick="document.execCommand('selectAll', false, null)"
  />
</template>

<script>
export default {
  props: {
    value: {},
    decimals: {
      type: Number,
      default: 1,
    },
    tag: {
      type: String,
      default: "%",
    },
    color: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listeners() {
      return { ...this.$listeners, input: this.onInput };
    },
  },
  mounted() {
    // TODO: why is this try/catch needed?
    try {
      this.$refs.editable.innerText = this.value.toFixed(this.decimals);
    } catch (error) {
      this.$refs.editable.innerText = +this.value;
    }
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.innerText);
    },
  },
};
</script>