import axios from "@/services/api";
import UserService from "@/services/UserService";

export default class CoreService {

    private userService = new UserService();



    public async getData(system: any) {
        try {
            const response = await axios.get(
                `/api/v1/core/?system=${system}`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async getAtoms(system: any) {
        try {
            const response = await axios.get(
                `/api/v1/mind/list`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }


    private getHeaders() {
        return {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        };
    }
}
