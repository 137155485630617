







































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";

@Component({
  name: "tree-view-component",
  components: {},
})
export default class TreeViewComponent extends Vue {
  @Prop() public item!: any;
  @Prop() public isOpen!: any;

  // private isOpen = false;
  private series = [];

  get isFolder() {
    return this.item.children && this.item.children.length;
  }

  private toggle() {
    this.isOpen = !this.isOpen;
  }
  private getSeries(nav: string) {
    // this.ds.getNavigator(nav).then((response) => {
    //   this.series = response;
    //   this.$emit("got-series", this.series);
    // });
  }
  private createSymbolTemplate(template: string) {
    // axios.get("/nav/countries.json").then((response) => {
    //   const countries = response.data;
    //   axios.get("/nav/" + template + ".json").then((responseInner) => {
    //     const data = responseInner.data;
    //     for (const k in data.options) {
    //       if (data.options[k].options === "countries") {
    //         data.options[k].options = countries;
    //       }
    //     }
    //     this.$emit("create-symbol-template", data);
    //   });
    //   //   this.$emit("create-symbol-template", response.data);
    // });
  }
}
