






























































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import TreeViewComponent from "@/components/TreeViewComponent.vue";
import MindService from "@/services/MindService";
import { SignalCalculator } from "@/models/SignalCalculator";
import Editable from "@/components/Editable.vue";
import { BIcon, BIconInfoCircle } from "bootstrap-vue";

@Component({
  components: {
    TreeViewComponent,
    Editable,
    BIconInfoCircle,
  },
  filters: {
    formatSignals(value: number) {
      if (Math.sign(value) === -1) {
        return `(${Math.abs(value).toFixed(0)}%)`;
      }

      return value.toFixed(0) + "%";
    },
  },
})
export default class AtomListView extends Vue {
  private mindService = new MindService();
  private calculator = new SignalCalculator();

  private mindResponse: any = null;
  private showDetails = false;
  private externalView = false;
  private refreshIdx = 0;
  private loading = false;

  private atomFilters: any = {
    asset: {
      selected: "eq",
      options: [
        { text: "Equity", value: "eq" },
        { text: "Equity Diff", value: "eqd" },
        { text: "Long Rate", value: "lr" },
        { text: "Short Rate", value: "sr" },
        { text: "FX", value: "fx" },
        { text: "Commodity", value: "cmd" },
      ],
    },
    eqCountry: {
      selected: "DW",
      options: [
        { text: "DW", value: "DW" },
        { text: "EM", value: "EM" },
        { text: "USA", value: "USA" },
        { text: "GBR", value: "GBR" },
        { text: "JPN", value: "JPN" },
        { text: "DEU", value: "DEU" },
        { text: "FRA", value: "FRA" },
        { text: "ITA", value: "ITA" },
        { text: "AUS", value: "AUS" },
        { text: "CAN", value: "CAN" },
      ],
    },
    eqdCountry: {
      selected: null,
      options: [{ text: "AUSvCAN", value: "AUSVCAN" }],
    },
    lrCountry: {
      selected: null,
      options: [{ text: "USA", value: "USA" }],
    },
  };

  private mounted() {
    this.getCore();
  }

  get filters() {
    const filters: any = {
      asset: this.atomFilters.asset.selected,
      assetSub: null,
    };
    if (filters.asset === "eq") {
      filters.assetSub = this.atomFilters.eqCountry.selected;
    } else if (filters.asset === "lr") {
      filters.assetSub = this.atomFilters.lrCountry.selected;
    }

    return filters;
  }

  private getCore() {
    this.loading = true;
    const filters = this.filters;

    this.mindService.postGetAtoms(filters).then((response) => {
      this.mindResponse = response.data;
      this.calculator = new SignalCalculator(this.mindResponse.atoms);
      this.calculator.totalSignal;
      this.loading = false;
    });
  }

  private resetFilters() {
    for (const k in this.atomFilters) {
      this.atomFilters[k].selected = null;
    }
    this.getCore();
  }

  private toggleShowDetailsOnAtom(atom: any, refresh = true) {
    if (atom.showDetails === undefined) {
      atom.showDetails = true;
    } else {
      atom.showDetails = !atom.showDetails;
    }
    if (refresh) {
      this.refreshIdx += 1;
    }
  }

  private toggleAllAtoms() {
    this.showDetails = !this.showDetails;
    for (const atom of this.mindResponse.atoms) {
      // this.toggleShowDetailsOnAtom(atom, false);
      atom.showDetails = this.showDetails;
    }
    this.refreshIdx += 1;
  }

  private overrideSignal() {
    const filters = this.filters;
    filters.totalSignal = this.calculator.totalSignal;
    filters.atoms = this.calculator.logics;
    this.mindService.overrideSignal(filters).then((response) => {
      this.showToast(response.data.message, "success", 3000);
    });
  }

  private showToast(
    text: string,
    kind = "default",
    delay = 2500,
    extraId = null
  ) {
    this.$bvToast.toast(text, {
      autoHideDelay: delay,
      appendToast: false,
      noCloseButton: true,
      variant: kind,
      id: extraId,
      solid: false,
    });
  }
}
