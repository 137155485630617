





























































































import { Component, Vue } from "vue-property-decorator";
import TreeViewComponent from "@/components/TreeViewComponent.vue";
import axios from "axios";

@Component({
  components: {
    TreeViewComponent,
  },
})
export default class Navigator extends Vue {
  private treeData = {
    name: "By Concepts",
    open: true,
    children: [
      {
        name: "Economics",
        children: [
          {
            name: "Growth",
            children: [{ name: "Coincident" }, { name: "Leading" }],
          },
        ],
      },
      {
        name: "Markets",
        children: [
          { name: "Equity", children: [{ name: "USA" }, { name: "GBR" }] },
        ],
      },
    ],
  };
  private hover = false;

  private knowledge = [{ name: "" }];

  private weight = {
    value: "100%",
    edit: false,
  };

  private gotSeries(res: any) {
    console.log("blah");
  }
}
