




























































import { Component, Vue } from "vue-property-decorator";
import CoreService from "@/services/CoreService";

@Component({
  components: {},
})
export default class AssetView extends Vue {
  private coreService: any = new CoreService();
  private knowledgeList: any = null;

  private mounted() {
    this.getCore();
  }

  private getCore(system: any = null) {
    this.coreService.getData(system).then((response: any) => {
      this.knowledgeList = response;
    });
  }
}
