export class SignalCalculator {
    public logics: any[] = [];


    constructor(logics: any[] = []) {
        this.logics = logics;
    }

    get totalSignal() {
        let signal = 0;
        for (const l of this.logics) {
            signal = signal + +l.weight_e.override / 100 * +l.signal_e.override;
        }
        return signal;
    }

    get totalWeight() {
        let w = 0;
        for (const l of this.logics) {
            w = w + +l.weight_e.override;
        }
        return w;
    }

    public reset() {
        for (const l of this.logics) {
            l.weight_e.override = l.weight_e.value;
            l.signal_e.override = l.signal_e.value;
        }
    }

    public autorescale() {
        let fixedWeights = 0;
        let nonFixedWeights = 0;

        for (const l of this.logics) {
            if (typeof l.weight_e.override === 'string') {
                fixedWeights = fixedWeights + +l.weight_e.override;
            } else {
                nonFixedWeights = nonFixedWeights + +l.weight_e.value;
            }
        }
        let ratio;
        if (fixedWeights <= 100) {
            ratio = (100 - fixedWeights) / nonFixedWeights;

            for (const l of this.logics) {
                if (typeof l.weight_e.override !== 'string') {
                    l.weight_e.override = +l.weight_e.value * ratio;
                }
            }
        }
    }
}
