import axios from "@/services/api";
import UserService from "@/services/UserService";

export default class MindService {

    private userService = new UserService();

    public async getAtoms() {
        try {
            const response = await axios.get(
                `/api/v1/atoms/list/`,
                this.getHeaders());
            return response.data;
        } catch (error) {
            return 0;
        }
    }

    public async postGetAtoms(filters: any) {
        const response = await axios.post("/api/v1/atoms/list/", filters,
            this.getHeaders());
        return response;
    }

    public async overrideSignal(datum: any) {
        const response = await axios.post("/api/v1/atoms/override/", datum,
            this.getHeaders());
        return response;
    }



    private getHeaders() {
        return {
            headers: {
                'Authorization': 'Token ' + this.userService.getSelf().token,
                'Content-Type': 'application/json'
            }
        };
    }
}
