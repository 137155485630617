import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import Home from '../views/Home.vue'
import LoginView from '../views/LoginView.vue';
import LogoutView from '../views/LogoutView.vue';
import CoreView from '../views/CoreView.vue';
import AtomView from '../views/AtomView.vue';
import AtomListView from '../views/AtomListView.vue';

import UserService from '../services/UserService';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: AtomListView,
    meta: { requiresAuth: true, clear: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/atom',
    name: 'atom',
    component: AtomView
  },
  {
    path: '/atoms',
    name: 'atomlist',
    component: AtomListView
  },
  {
    path: '/core',
    name: 'core',
    component: CoreView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const userService = new UserService();


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!userService.getSelf()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {

      if (to.meta.clear) {
        next();
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router
